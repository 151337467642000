<template>
  <div class="row">
    <div class="col-12">
      <div class="integration__header">
        <div class="d-flex flex-column">
          <h3 class="mb-1 emm__page-heading text-uppercase">
          {{ $route.params.slug === 'zapier' ? $route.params.slug + '/Webhook' : $route.params.name }}
          </h3>
          <span class="emm__page-sub-heading">Get your emails to the inbox—where they belong</span>
        </div>
      </div>
      <!-- <card bodyClasses="rounded-2" class="overflow-hidden rounded-2 mb-0 mt-5">
        <h4>Campaigns Attatched</h4>
      </card> -->
      <card bodyClasses="rounded-2" class="overflow-hidden rounded-2 mb-0 mt-5">
        <div class="d-flex align-items-end justify-content-between">
          <h4>{{ $route.params.slug === 'zapier' ? $route.params.slug + '/Webhook' : $route.params.name }} configuration</h4>
          <!-- Zapier Button save -->
          <base-button v-if="$route.params.slug === 'zapier'" id="btnSaveIntegration" @click="saveIntegrationSettingslocal"
          :disabled="isLoading || isZapierEmpty" class="m-0">Save</base-button>
          <!-- Zapier Button save -->

          <!-- CLICK FUNNELS BUTTON SAVE -->
          <base-button v-else-if="$route.params.slug === 'clickfunnels'" id="btnSaveIntegration" @click="saveIntegrationSettingslocal"
          :disabled="isLoading || !userApiKey || clickfunnels.subdomain.trim() === '' || clickfunnels.workspaceId.trim() === ''" class="m-0">Save</base-button>
          <!-- CLICK FUNNELS BUTTON SAVE -->

          <base-button v-else id="btnSaveIntegration" @click="saveIntegrationSettingslocal"
            :disabled="isLoading || !userApiKey" class="m-0">Save</base-button>
        </div>
        <div style="gap: 12px" class="d-flex flex-column mt-4">
          <base-checkbox v-model="integrationDetails.enable_sendgrid">Enable {{ $route.params.slug === 'zapier' ? $route.params.slug + '/Webhook' : $route.params.name }}</base-checkbox>
          <base-input v-if="$route.params.slug !== 'zapier'" type="text" clearable label="Enter your API Key" placeholder="GJYEzzse345673htj"
            class="flex-grow-1 mb-0" v-model="userApiKey" aria-controls="datatables">
          </base-input>

          <div class="has-label" v-if="$route.params.slug === 'zapier'">
            <p>Enter your Webhook URL</p>
            <div style="display: flex; flex-direction: column; gap: 8px;">
              <div v-if="zapier.length === 0">
                <div class="webhook-url-wrapper">
                    <base-input type="text" clearable
                        placeholder="https://hooks.app.com/hooks/catch/....." class="flex-grow-1 mb-0"
                        v-model="zapier[0]" aria-controls="datatables">
                    </base-input>
                    <span class="add-webhook-url" @click="addWebhookUrl">
                        <i class="fa-solid fa-plus"></i>
                    </span>
                </div>
              </div>
              <div v-else>
                <div v-for="(url, index) in zapier" :key="index" class="webhook-url-wrapper">
                    <base-input type="text" clearable
                        placeholder="https://hooks.app.com/hooks/catch/....." class="flex-grow-1 mb-0"  v-model="zapier[index]"  aria-controls="datatables">
                        
                    </base-input>
                    <span 
                        v-if="index == 0" 
                        class="add-webhook-url" 
                        @click="addWebhookUrl">
                        <i class="fa-solid fa-plus"></i>
                    </span>
                    <span 
                          v-if="index != 0 && zapier.length > 1" 
                        class="add-webhook-url" 
                          @click="removeWebhookUrl(index)">
                        <i class="fa-solid fa-minus"></i>
                    </span>
                </div>
              </div>
            </div>
          </div>
          <base-checkbox v-if="$route.params.slug === 'zapier'" v-model="sendTestZapier">Send Test Data</base-checkbox>
          <p class="m-0" v-if="$route.params.slug === 'zapier'">* check and click save to send dummy data to your webhook</p>
          <div v-if="$route.params.slug === 'mailboxpower'" class="d-flex" style="flex-direction: column; gap: 12px; margin-top: 25px" >
              <span style="display: flex; align-items: center;gap: 8px">
               <el-button
                   size="medium"
                   icon="el-icon-unlock"
                   @click="redirectToLink(`https://mailboxpower.com/${refcode}`)"
                   circle
                
                   ></el-button> <span>If you do not have a Mailbox Power account, <a style="color: inherit !important;font-size: inherit;font-weight: bold;" :href="`https://mailboxpower.com/${refcode}`" target="_blank">Click Here</a> to create one</span>
               </span>
               <span style="display: flex; align-items: center;gap: 8px">
               <el-button
                   size="medium"
                   icon="el-icon-s-promotion"
                   @click="redirectToLink(`https://app.mailboxpower.com/demo/${refcode}`)"
                   circle
                   ></el-button> 
               <a style="color: inherit !important;font-size: inherit;font-weight: inherit" class="d-block" :href="`https://app.mailboxpower.com/demo/${refcode}`" target="_blank">Learn More about the Integration</a>
               </span>
          </div>
        
          <base-input v-if="$route.params.slug === 'kartra'" type="text" clearable label="Enter your API Password"
            placeholder="GJYEzzse345673htj" class="flex-grow-1 mb-0" v-model="userApiPassword"
            aria-controls="datatables">
          </base-input>
        </div>
        <div v-if="$route.params.slug === 'kartra'">
          <p class="mt-4 d-block"><strong>Note:</strong> When you click Save, this app will create the below custom
            fields in your Kartra account. These fields are Required for this connection to work properly.
            As per Kartra's policy, once a custom field is deleted, it cannot be recreated. Therefore, <span
              class="text-underline">Do NOT</span> delete or rename the below custom fields in your Kartra account.
            <strong style="cursor: pointer;" v-if="!showKartraCustomFields"
              @click="showKartraCustomFields = !showKartraCustomFields">show more</strong></p>

          <ul v-show="showKartraCustomFields">
            <li>secondphone</li>
            <li>secondemail</li>
            <li>keyword</li>
            <li>secondaddress</li>
          </ul>
        </div>
        <!-- <div class="mt-lg-5 d-flex align-items-end justify-content-end">
          <base-button @click="saveIntegrationSettingslocal" :disabled="isLoading || !userApiKey"
            class="m-0">Save</base-button>
        </div> -->
        <!-- <div class="mt-4">
          <label class="d-block mb-0" for="contact-upload"
            >Upload your contact list</label
          >
       
          <form enctype="multipart/form-data">
          
            <div class="dropbox">
              <input
                id="contact-upload"
                type="file"
                :name="uploadFieldName"
                :disabled="isSaving"
                @change="
                  filesChange($event.target.name, $event.target.files);
                  fileCount = $event.target.files.length;
                "
                accept=".csv"
                class="input-file"
              />
              <p>
                Drag your csv file here to upload<br />or click to browse<br />
                <base-button
                  type="info"
                  round
                  icon
                  @click="show_helpguide('optoutlist')"
                >
                  <i class="fas fa-question"></i>
                </base-button>
              </p>
              <p v-if="isSaving">
                Please Wait, your file is being processed ...
              </p>
              <p v-if="isSuccess">Process finished successfully, Thank you!</p>
              <p v-if="isFailed">
                Whoops that did not work, please check your file for errors and
                try again
              </p>
            </div>
          </form>
        </div> -->
        <div v-if="$route.params.slug === 'clickfunnels'" style="margin-top: 8px; display: flex; flex-direction: column; gap: 8px;">
          <label class="mb-0">Subdomain</label>
          <el-input v-model="clickfunnels.subdomain" type="text" clearable label="Enter your subdomain"
              placeholder="richstone" class="flex-grow-1 mb-0 input__transparent"
              aria-controls="datatables">
              <template :slot="windowWidth > 576 && 'prepend'">https://</template>
              <template :slot="windowWidth > 576 && 'append'">.myclickfunnels.com</template>
          </el-input>
          <small v-if="windowWidth < 576" class="wrap-text">Example: https://{{ clickfunnels.subdomain ? clickfunnels.subdomain : 'yoursubdomain' }}.myclickfunnels.com</small>
          <label class="mb-0">Workspace ID</label>
          <el-input v-model="clickfunnels.workspaceId" type="text" clearable label="Enter your workspaces"
              placeholder="jWMWXZ" class="flex-grow-1 mb-0 input__transparent"
              aria-controls="datatables">
              <template :slot="windowWidth > 992 && 'prepend'">https://{{ clickfunnels.subdomain }}.myclickfunnels.com/account/workspaces/</template>
          </el-input>
          <small v-if="windowWidth < 992" class="wrap-text">Example: https://{{ clickfunnels.subdomain ? clickfunnels.subdomain : 'yoursubdomain' }}.myclickfunnels.com/account/workspaces/workspaceid</small>        </div>

        <div v-if="overideIntegrationSlug === 'clickfunnels' || overideIntegrationSlug === 'gohighlevel'">
          <CheckboxCustomFields
              :classCheckbox="'checkbox-custom-fields-integrations'"
              :slug="overideIntegrationSlug"
              :isLoadingCustomFieldsBySlug="isLoadingListCustomFieldsIntegration"
              :isErrorCustomFieldsBySlug="errorListCustomFieldsIntegration"
              :handleDefaultCustomFields="getCustomFields"
              :customFieldsBySlug="listCustomFieldsIntegration"
              :listValueFieldsIntegration="listValueCustomFieldsIntegration"
              :listValueFieldsIntegrationB2b="listValueCustomFieldsIntegrationB2b"
              :listValueFieldsIntegrationAdvance="listValueCustomFieldsIntegrationAdvance"
              :handleCheckItemCustomFields="handleCheckItemCustomFields"
              :isHideB2b="isHideB2b"
              :isHideAdvance="isHideAdvance"
          />
        </div>
        <div  class="mt-5" style="font-size: 12px;">
              Note: Some of the links on this site are affiliate links, meaning we may earn a commission if you sign up or make a purchase through them. This helps support our work at no additional cost to you.
          </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Input,Button, Divider, Checkbox, MessageBox, Card } from 'element-ui'
import { SkeletonCheckbox } from '../../components'
import { decryptData } from '../../helpers/crypto'
import CheckboxCustomFields from '../../components/CheckboxCustomFields.vue'

export default {
  components: {
    [Input.name] : Input,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Divider.name]: Divider,
    'el-card': Card,
    SkeletonCheckbox,
    CheckboxCustomFields,
  },
  data() {
    return {
      userApiKey: '',
      userApiPassword: '',
      isLoading: false,
      showKartraCustomFields: false,
      integrationDetails: {},
      zapier: [""],
      sendTestZapier: false,
      companyId: '',
      refcode: 'C8GWKU',
      clickfunnels: {
        subdomain: '',
        workspaceId: '',
      },
      windowWidth: window.innerWidth,
      listCustomFieldsIntegration: [],
      isLoadingListCustomFieldsIntegration: false,
      errorListCustomFieldsIntegration: false,
      listValueCustomFieldsIntegration: [],
      listValueCustomFieldsIntegrationB2b: [],
      listValueCustomFieldsIntegrationAdvance: [],
      prevListValueCustomFieldsIntegration: [],
      prevListValueCustomFieldsIntegrationB2b: [],
      prevListValueCustomFieldsIntegrationAdvance: [],
      isHideB2b: false,
      isHideAdvance: false,
    }
  },
  async created() {
    const userData = this.$store.getters.userData
    this.companyId = userData.company_id
    this.integrationDetails = await this.getUserIntegrationDetails({ companyID: this.companyId, slug: this.overideIntegrationSlug })
    var _integrationDetails = this.integrationDetails;
    //console.log(_integrationDetails);
    const matchSlug = this.integrationDetails.refcode.find(itemFind => itemFind.slug === this.overideIntegrationSlug);
    if (matchSlug) {
      let parsedData = JSON.parse(`[${matchSlug.referralcode}]`);
      if (Array.isArray(parsedData)) {
          parsedData.forEach(item => {
              if (item.rootid == this.$global.idsys) {
                  this.refcode = item.refcode;
              }
              
          });
      }
      
    }
    if (_integrationDetails.data && Array.isArray(_integrationDetails.data)) {
                    _integrationDetails.data.forEach(item => {
                        this.integrationDetails.enable_sendgrid = item.enable_sendgrid === 1;
                        this.userApiKey = item.api_key ? item.api_key : '';
                        if(this.overideIntegrationSlug === 'kartra'){
                            this.userApiPassword = item.password
                        }

                        if(this.overideIntegrationSlug === 'zapier'){
                          const result = item.api_key.map(item => item);
                          this.zapier = result
                        }

                        if(this.overideIntegrationSlug === 'clickfunnels'){
                          this.clickfunnels.subdomain = item.subdomain;
                          this.clickfunnels.workspaceId = item.workspace_id;
                        }
                    });
    }else {
      console.error("Integration details not found or not in the expected format.");
    }

    await this.getCustomFields()

    if(_integrationDetails.data && _integrationDetails.data[0].custom_fields){
      const listDisabledCustomFields = (this.listCustomFieldsIntegration || []).filter(item => item.disabled && item.type === 'general').map(item => item.name)
      const customFields = [...listDisabledCustomFields, ..._integrationDetails.data[0].custom_fields]
      
      this.listValueCustomFieldsIntegration = customFields;
    } else {
      this.listValueCustomFieldsIntegration = (this.listCustomFieldsIntegration || []).filter(item => item.type === 'general').map(item => item.name);
    }

    if(_integrationDetails.data && _integrationDetails.data[0].custom_fields_b2b){
      const listDisabledCustomFields = (this.listCustomFieldsIntegration || []).filter(item => item.disabled && item.type === 'b2b').map(item => item.name)
      const customFields = [...listDisabledCustomFields, ..._integrationDetails.data[0].custom_fields_b2b]
      
      this.listValueCustomFieldsIntegrationB2b = customFields;
    } else {
      this.listValueCustomFieldsIntegrationB2b = (this.listCustomFieldsIntegration || []).filter(item => item.type === 'b2b').map(item => item.name);
    }
    
    if(_integrationDetails.data && _integrationDetails.data[0].custom_fields_advance){
      const listDisabledCustomFields = (this.listCustomFieldsIntegration || []).filter(item => item.disabled && item.type === 'advance').map(item => item.name)
      const customFields = [...listDisabledCustomFields, ..._integrationDetails.data[0].custom_fields_advance]
      
      this.listValueCustomFieldsIntegrationAdvance = customFields;
    } else {
      this.listValueCustomFieldsIntegrationAdvance = (this.listCustomFieldsIntegration || []).filter(item => item.type === 'advance').map(item => item.name);
    }

    // FOR LOGS
    const listDisabledCustomFields = (this.listCustomFieldsIntegration || []).filter(item => item.disabled).map(item => item.name)
    const filteredListGeneral = (this.listValueCustomFieldsIntegration || []).filter(item => !listDisabledCustomFields.includes(item));
    const filteredListB2b = (this.listValueCustomFieldsIntegrationB2b || []).filter(item => !listDisabledCustomFields.includes(item));
    const filteredListAdvance = (this.listValueCustomFieldsIntegrationAdvance || []).filter(item => !listDisabledCustomFields.includes(item));
    this.prevListValueCustomFieldsIntegration = [...filteredListGeneral]
    this.prevListValueCustomFieldsIntegrationB2b = [...filteredListB2b]
    this.prevListValueCustomFieldsIntegrationAdvance = [...filteredListAdvance]
    // FOR LOGS
  },
  computed: {
    overideIntegrationSlug() {
      return this.$route.params.slug === 'leadconnector' ? 'gohighlevel' : this.$route.params.slug;
    },
    isZapierEmpty() {
      return this.zapier.length === 0 || this.zapier.every(item => item.trim() === "");
    },
  },
  mounted() {
    const sidebar = this.$global.clientsidebar
    if (!sidebar['b2b']){
        this.isHideB2b = true;
    }

    if (!sidebar['enhance']){
        this.isHideAdvance = true;
    }
    window.addEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    ...mapActions(["saveIntegrationSettings", "getUserIntegrationDetails", 'userLogSaved']),
    async saveIntegrationSettingslocal() {
      $('#btnSaveIntegration').html('Saving...');   
      this.isLoading = true
      let data = {
        integration_slug: this.overideIntegrationSlug,
        company_id: this.companyId,
        api_key: this.userApiKey,
        enable_sendgrid: this.integrationDetails.enable_sendgrid ? 1 : 0,
      }
      if(this.overideIntegrationSlug == 'kartra') data.password = this.userApiPassword;
      if(this.overideIntegrationSlug == 'gohighlevel'){
        try {
            await MessageBox.confirm('Confirm your updates. Unchecked fields will now stop sending this data to your CRM and checked fields will now create this custom field in your CRM, and start sending this data.', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });

        } catch (error) {
            $('#btnSaveIntegration').html('Save');
            this.isLoading = false
            return;
        }

        const { filteredListGeneral, filteredListB2b , filteredListAdvance } = this.handleFilterListCustomFields()
        
        data.custom_fields = filteredListGeneral;
        data.custom_fields_b2b = filteredListB2b;
        data.custom_fields_advance = filteredListAdvance;
      }
      if(this.overideIntegrationSlug === 'zapier'){
                data.send_test_zapier = this.sendTestZapier
                const validWebhookUrls = this.zapier.filter(item => item !== null && item != "");
                data.api_key = validWebhookUrls
      }
      if(this.overideIntegrationSlug === 'clickfunnels'){
        try {
            await MessageBox.confirm('Confirm your updates. Unchecked fields will now stop sending this data to your CRM and checked fields will now create this custom field in your CRM, and start sending this data.', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });

        } catch (error) {
            $('#btnSaveIntegration').html('Save');
            this.isLoading = false
            return;
        }
        
        data.subdomain = this.clickfunnels.subdomain;
        data.workspace_id = this.clickfunnels.workspaceId;

        const { filteredListGeneral, filteredListB2b } = this.handleFilterListCustomFields()
        
        data.custom_fields = filteredListGeneral;
        data.custom_fields_b2b = filteredListB2b;
      }
      const response = await this.saveIntegrationSettings({ data })

      // FOR LOGS
      if(response.result == 'success'){
          const userData = this.$store.getters.userData;
          
          if(this.overideIntegrationSlug === 'clickfunnels' || this.overideIntegrationSlug === 'gohighlevel'){
              let desc = `Company Id: ${this.companyId} | Integration Type: ${this.overideIntegrationSlug} | `;

              const appendDescription = (field, prevValue, newValue) => {
                  desc += `${field} : Update values from ${prevValue || 'Empty'} to: ${newValue || 'Empty'} | `;
              };
              const joinArray = (arr) => (arr && arr.length > 0 ? arr.join(', ') : 'Empty');

              const isChangedCustomFields = this.hasChangedArray(this.prevListValueCustomFieldsIntegration, data.custom_fields)
              const isChangedCustomFieldsB2b = this.hasChangedArray(this.prevListValueCustomFieldsIntegrationB2b, data.custom_fields_b2b)
              const isChangedCustomFieldsAdvance = this.hasChangedArray(this.prevListValueCustomFieldsIntegrationAdvance, data.custom_fields_advance)

              if(isChangedCustomFields){
                  appendDescription(
                      "Custom Fields",
                      joinArray(this.prevListValueCustomFieldsIntegration),
                      joinArray(data.custom_fields)
                  );
                  
                  desc += `The user has confirmed and agreed to this action.`;
              } else {
                  desc += `Custom Fields : No changes, value remains ${joinArray(data.custom_fields)}`;
              }

              if(isChangedCustomFieldsB2b){
                  appendDescription(
                      " | Custom Fields B2B",
                      joinArray(this.prevListValueCustomFieldsIntegrationB2b),
                      joinArray(data.custom_fields_b2b)
                  );
                  
                  desc += `The user has confirmed and agreed to this action.`;
              } else {
                  desc += ` | Custom Fields B2B : No changes, value remains ${joinArray(data.custom_fields)}`;
              }

              if(isChangedCustomFieldsAdvance){
                  appendDescription(
                      " | Custom Fields Enhance Advance",
                      joinArray(this.prevListValueCustomFieldsIntegrationAdvance),
                      joinArray(data.custom_fields_advance)
                  );
                  
                  desc += `The user has confirmed and agreed to this action.`;
              } else {
                  desc += ` | Custom Fields Enhance Advance : No changes, value remains ${joinArray(data.custom_fields)}`;
              }

              const payload = {
                  userID: userData.id,
                  action: "Custom Fields",
                  desc,
                  userIP: this.$store.getters.userData.ip_login,
              }
              const userViewMode = userData.isViewMode ? userData.isViewMode : false;
              if(userViewMode){
                  const userRoot = await decryptData(localStorage.getItem('userDataOri'))
                  payload.userID = userRoot.id;
              }
              this.userLogSaved({ data: payload });

              this.prevListValueCustomFieldsIntegration = data.custom_fields;
          }
      }
      // FOR LOGS

      $('#btnSaveIntegration').html('Save');
      this.isLoading = false
    },
    addWebhookUrl() {
        this.zapier.push('');
    },
    removeWebhookUrl(index) {
        if (this.zapier.length > 1) {
            this.zapier.splice(index, 1);
        }
    },
    async getCustomFields(){
      this.isLoadingListCustomFieldsIntegration = true;
      this.errorListCustomFieldsIntegration = false;

      const payload = {
        slug: this.overideIntegrationSlug,
      }

      await this.$store.dispatch('getCustomFieldsBySlug', payload).then(response => {
          this.listCustomFieldsIntegration = response.data.custom_fields;
          this.isLoadingListCustomFieldsIntegration = false;
      }).catch(error => {
          this.errorListCustomFieldsIntegration = true;
          this.isLoadingListCustomFieldsIntegration = false;
      })
    },
    handleCheckItemCustomFields(type, name, module){
        const handleModule = () => {
          switch (module) {
            case 'b2b':
              return 'B2b';
            case 'advance':
              return 'Advance'
            default:
              return ''
          }
        }

        const listValueIntegration = this[`listValueCustomFieldsIntegration${handleModule()}`];
        const isChecked = listValueIntegration.includes(name);

        MessageBox.confirm(
            isChecked 
                ? `Unchecking "${name}" will stop sending this data to your CRM. Are you sure?` 
                : `Checking "${name}" will create this custom field in your CRM, and start sending this data. Are your sure?`,
            'Warning',
            {
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }
        ).then(() => {
            if (isChecked) {
                // this.listValueCustomFieldsIntegration = listValueIntegration.filter(item => item !== name);
                this[`listValueCustomFieldsIntegration${handleModule()}`] = listValueIntegration.filter(item => item !== name);
            } else {
                // this.listValueCustomFieldsIntegration.push(name);
                this[`listValueCustomFieldsIntegration${handleModule()}`].push(name);
            }
        }).catch(() => {
            return;
        });
    },
    hasChangedArray(prevData, currentData){
        if (prevData.length !== currentData.length) {
            return true;
        }

        // Check each element in the array
        for (let i = 0; i < prevData.length; i++) {
            if (prevData[i] !== currentData[i]) {
                return true; // Return true if any element is different
            }
        }

        // Return false if all elements are the same
        return false;
    },
    getCustomFieldsByType(type, fields, filteredList) {
        const listCustomFields = fields
            .filter(item => item.type === type)
            .map(item => item.name);

        return filteredList.filter(item => listCustomFields.includes(item));
    },
    handleFilterListCustomFields() {
      const listDisabledCustomFields = this.listCustomFieldsIntegration.filter(item => item.disabled).map(item => item.name)
      const filteredListGeneral = this.listValueCustomFieldsIntegration.filter(item => !listDisabledCustomFields.includes(item));
      const filteredListB2b = this.listValueCustomFieldsIntegrationB2b.filter(item => !listDisabledCustomFields.includes(item));
      const filteredListAdvance = this.listValueCustomFieldsIntegrationAdvance.filter(item => !listDisabledCustomFields.includes(item));

      return { filteredListGeneral, filteredListB2b, filteredListAdvance }
    },
    updateWindowWidth() {
        this.windowWidth = window.innerWidth;
    }
  },
  beforeDestroy() {
      window.removeEventListener("resize", this.updateWindowWidth);
  }
};
</script>

<style>
.add-webhook-url{
    height: 40px;
    width: 40px;
    background: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    color: white;
}
.webhook-url-wrapper{
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
}

.input__transparent .el-input__inner {
  background-color: transparent !important;
  color: var(--primary-input-text-color);
}

.checkbox-custom-fields-integrations {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.checkbox-custom-fields-integrations .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: var(--btn-primary-color);
}

.checkbox-custom-fields-integrations .el-checkbox__inner {
    border-color: var(--btn-primary-color);
    background-color: transparent;
    border-radius: 3px;
}

.checkbox-custom-fields-integrations .el-checkbox__inner:hover {
    border-color: var(--btn-primary-color);
}

.checkbox-custom-fields-integrations .el-checkbox__input.is-checked+.el-checkbox__label {
    color: var(--text-primary-color);
}

.checkbox-custom-fields-integrations .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: var(--btn-primary-color);
    background-color: var(--btn-primary-color);
    border-radius: 3px;
}

.checkbox-custom-fields-integrations .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: var(--btn-primary-color);
    background-color: var(--btn-primary-color);
}

.checkbox-custom-fields-integrations .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  opacity: 0.6;
}

.checkbox-custom-fields-integrations .el-checkbox__input.is-disabled+span.el-checkbox__label {
  opacity: 0.6;
}

.error-banner {
    padding: 8px 16px;
    background-color: #ffe6e6; /* Light red background for error */
    border-radius: 4px;
    border-left: 5px solid #dc3545; /* Red border for error */
    margin: 20px 0;
    font-size: 14px;
    color: #721c24; /* Darker red text for readability */
    line-height: 1.5em;
}

.error-banner p {
    margin: 0;
}

@media (max-width: 768px) {
  .checkbox-custom-fields-integrations {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 469px) {
    .checkbox-custom-fields-integrations {
        grid-template-columns: repeat(1, 1fr); 
    }
}
</style>